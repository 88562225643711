import React, { useState } from "react";

//Material UI
import { ProjectCompensationBox } from "./ActionPlanSection";
import InfoBox from "components/Base/InfoBox";

import api from "services/api";
import { formatAndConvertNumber } from "../../utils";
import MyIcon from "components/partials/MyIcon";
import { Modal } from "components/modal";

const ModalCompensation = (props) => {
  const { isOpen, close, title, totalEmissions, user } = props;

  const [error, setError] = useState(null);

  const { number: emissions, unit: emissionsUnit } = formatAndConvertNumber(totalEmissions, "emissions");
  const compensationAmount = (totalEmissions / 1000) * 18;

  const handleValidate = () => {
    const body = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      message: `DEMANDE DE COMPENSATION / Projet ${title} / Montant compensation : ${compensationAmount} €`,
      type: "compensation",
    };
    api
      .post("/auth/contact", body)
      .then((res) => {
        if(res.ok){
          setError({ message: "Le message a bien été envoyé" });
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <Modal isOpen={isOpen} onClose={close} id="modal_compensation" className="scrollbar overflow-y-auto pr-5">
      <h2 className="mb-6 font-bold text-2xl leading-8">Compensation</h2>

      <ProjectCompensationBox title={title} emissions={emissions} emissionsUnit={emissionsUnit} compensationAmount={compensationAmount} />

      <p className="font-normal text-xs leading-4">
        Secoya vous propose ainsi de participer à la neutralité carbone en finançant ce programme de compensation, rigoureusement sélectionné parmi de nombreux projets existants,
        avec une démarche simple, en 3 étapes :
      </p>
      <ul className="list-none w-4/5">
        <li className="flex items-center my-2">
          <img src="/images/icons/checkBlue.svg" alt="check" className="mr-2" />
          <p className="mb-0 text-left">
            Cliquez sur <b>"je souhaite participer à la neutralité carbone"</b>
          </p>
        </li>
        <li className="flex items-center my-2">
          <img src="/images/icons/checkBlue.svg" alt="check" className="mr-2" />
          <p className="mb-0 text-left">Secoya vous contacte pour valider le projet de compensation</p>
        </li>
        <li className="flex items-center my-2">
          <img src="/images/icons/checkBlue.svg" alt="check" className="mr-2" />
          <p className="mb-0 text-left">Secoya gère l'interface avec son partenaire OCO et vous fournit un certificat officiel, attestant de la démarche</p>
        </li>
      </ul>

      {error && <InfoBox error={error}>{error.message}</InfoBox>}

      <div className="mt-10 flex gap-x-4 w-4/5 ml-auto justify-end">
        <button className="empty-button flex items-center justify-center gap-2 pl-2" onClick={close}>
          <MyIcon icon="back" />
          Retour
        </button>
        <button className="blue-button" onClick={handleValidate}>
          Je souhaite participer à la compensation
        </button>
      </div>

      {/* <div className="w-full flex justify-between items-center p-6 bg-blue-light">
        <img src="/images/PUR Projet.jpg" alt="PUR Projet" className="w-2/5 mr-5"/>
        <div className="flex flex-col">
          <p className="font-normal text-xs leading-4">
            <b>PUR Projet</b> est une entreprise à mission. Elle travaille avec les entreprises pour régénérer les écosystèmes en développant des projets sociaux et
            environnementaux. Leur objectif est de donner les capacités aux communautés avec qui elle développe les projets de protéger les écosystèmes dont elles dépendent en
            menant des activités d'agriculture durable, d'agroforesterie et de restauration de terres dégradées.
          </p>
          <br />
          <p className="font-normal text-xs leading-4">
            Fondée en 2008 avec l'objectif de combattre le changement climatique et la déforestation, PUR Projet a développé et opère 56 projets dans plus de 40 pays.
          </p>
          <br />
          <Button width="70%">
            <a href="/Info_PUR_projet.pdf" download>
              Projets de compensation
            </a>
          </Button>
        </div>
      </div> */}
    </Modal>
  );
};

export default ModalCompensation;
