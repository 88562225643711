import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import api from "services/api";
import { RECOMMENDATIONS } from "../utils/static";

const ActionPlanSection = ({ estimation, openCompensation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInscription = () => {
    api.put(`/estimations/${estimation._id}/registration`, { registration: true }).then((res) => {
      if (res.ok) setIsModalOpen(true);
    });
  };

  return (
    <div className="bg-backgrounds p-8 w-full my-8 action_plan">
      <h3 className="font-bold text-[25px] leading-[33px] pb-4">Réduire mes émissions</h3>

      <p className="font-normal text-blue-dark">
        L'estimation vous a permis d'identifier les principaux postes d'émission de gaz à effet de serre de votre tournage. Et si nous les réduisions ? Retrouvez ci-dessous un plan
        de recommandations détaillé, accompagné de notre réseau de partenaires qualifiés.
      </p>
      <div className="my-6 p-6 bg-blue-gray">
        <p className="font-normal">
          Prochainement : un plan d'action détaillé et adapté à votre propre tournage !{" "}
          <span className="underline" onClick={handleInscription}>
            Inscrivez-vous
          </span>{" "}
          pour y accéder en avant-première sur vos prochains tournages.
        </p>
      </div>

      <div className="bg-white p-6">
        <h2 className="font-bold text-xl leading-6 text-blue-dark mb-4">Recommandations</h2>
        <Recommendations />
      </div>
      <div className="my-6 p-6 bg-blue-gray">
        <h4 className="font-bold text-xl leading-7 text-blue-dark mb-3">Et après ?</h4>
        <p className="font-normal mb-3">Avez-vous mis en place tous les efforts possibles de réduction de vos émissions ?</p>
        <p className="font-normal mb-4">
          Il existe une autre façon de participer à la démarche carbone : la contribution à la neutralité carbone planétaire. Avec notre partenaire OCO, nous vous offrons la
          possibilité de contribuer à la réduction des émissions.
        </p>
        <button className="orange-button w-56" onClick={() => openCompensation()}>
          Participer
        </button>
      </div>
      <InscriptionConfirmationModel isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </div>
  );
};

export const formatNumber = (number) => {
  if (isNaN(number)) {
    if (!isNaN(Math.round(number))) {
      number = Math.round(number);
    } else {
      return number; //could be a string
    }
  }
  number > 10 ? (number = Math.round(number)) : (number = Math.round(number * 10) / 10);
  number = number.toLocaleString();
  return number;
};

export const ProjectCompensationBox = (props) => {
  const { title, emissions, emissionsUnit, compensationAmount } = props;
  return (
    <div className="mt-6 p-6 bg-back-light-blue">
      <p className="font-normal">
        L'impact carbone de la production de {title} est estimé à{" "}
        <b>
          {emissions} {emissionsUnit}
        </b>
        .
      </p>
      <br />
      <p className="font-normal">
        Le prix de la tonne carbone proposé par OCO, en partenariat avec Secoya, est de 18€ HT/tonne sur le projet REDD+ (description ci-dessous).Le montant de la contribution
        carbone de {title} revient à <b>{formatNumber(compensationAmount)} €</b> HT.
      </p>
    </div>
  );
};

const Recommendations = () => {
  const [dataTab, setDataTab] = useState(RECOMMENDATIONS[0]);

  return (
    <>
      <p className="mb-2.5">Pour vous aider à réduire votre empreinte carbone voici un ensemble de recommandations</p>
      <div className="grid grid-cols-4 gap-4 h-[392px]">
        <div className="flex flex-col justify-between">
          {RECOMMENDATIONS.map((category, i) => (
            <button
              key={i}
              className={`w-full py-2.5 text-sm font-medium leading-5 hover:bg-blue-dark hover:text-white transition-colors ${
                category.category === dataTab.category ? "bg-blue-dark shadow text-white" : "bg-back-light-blue text-blue-dark"
              }`}
              onClick={() => setDataTab(category)}>
              {category.category}
            </button>
          ))}
        </div>

        <div className="col-span-3 space-y-5 overflow-scroll">
          <div>
            {dataTab.items.map((data, idx) => (
              <article className="text-blue-dark" key={idx}>
                <h6 className="font-bold">{data.action}</h6>
                <p className="mb-5">{data.description}</p>
                {data.url !== "" && (
                  <a href={data.url} target="_blank" className="underline" rel="noreferrer">
                    {data.urlName || "Infos"}
                  </a>
                )}
              </article>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const InscriptionConfirmationModel = ({ isOpen, setOpen }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
              <p className="text-sm text-blue-dark">
                Votre inscription est bien enregistrée ! Vous serez recontacté par les équipes de Secoya dès que la fonctionnalité sera prête. En attendant, retrouvez le plan de
                recommandations dans votre synthèse.
              </p>
              <div className="mt-4 w-full flex justify-end">
                <button type="button" className="blue-button w-26" onClick={() => setOpen(false)}>
                  Ok
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default ActionPlanSection;
